//	E X P O R T   L I B

export const lib_names = {
	aspectRatios: {
		poster: {
			h: 16,
			w: 9,
			ar: 9 / 16,
		}
	},
	endpoints: {
		qrs_generator: 'https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data=',
		server_api: 'https://legacy-server.nopr.me/',
	//	server_api: 'http://localhost:3000/',
	},
	MapBoxGL: {
		accessToken: 'pk.eyJ1Ijoicmlrb3p6IiwiYSI6ImNsazAzeTVzZjBnZ2ozZG1sOXNkbmdpMHMifQ.YEm8hzqY7Q0WBewrRtl6ig',
		accessToken_geocoding: 'pk.eyJ1Ijoicmlrb3p6IiwiYSI6ImNsazAzeTVzZjBnZ2ozZG1sOXNkbmdpMHMifQ.YEm8hzqY7Q0WBewrRtl6ig',
		attributionControl: false,
		doubleClickZoom: false,
		dragRotate: false,
		markerColor: '#023e8a',
		mapbox_fly_options: {
			speed: 2,
			curve: 1,
			essential: true
		},
		maxPitch: 0,
		maxZoom: 20,
		minZoom: 1,
		minZoomToDiscover: 7.5,
		style: 'mapbox://styles/rikozz/clk0414x700a201pfdf0z70gh',
		style_satellite: 'mapbox://styles/rikozz/clngobzt9001p01nuacx5dv1v',
	//	style: 'mapbox://styles/rikozz/clje6tj1j005301qs0cty9ewk/draft',
		touchPitch: false,
		zoom: 11.5,
		zoomPreview: 15.5,
	}
}